import React from 'react';
import YouTubeClipRepeater from './YouTubeClipRepeater'

const MediaTypes = {
  YouTube: 1,
  Video: 2,
  Iframe: 3,
  Image: 4
}

const allResults = [
  {
    title: '',
    type: MediaTypes.YouTube,
    videoId: '3Ywwb1OnfGk',
    start: 203,
    end: 204,
    muteAfterFirstLoop: true
  },
  {
    title: 'Yes',
    type: MediaTypes.Video,
    src: "https://i.giphy.com/media/l3q2UsXybI4PwAYr6/giphy.mp4"
  },
  {
    title: '',
    type: MediaTypes.Iframe,
    src: "https://giphy.com/embed/l3vR7zPylPYGCtVqo",
    width: "480",
    height: "254"
  },
  {
    title: '*Steven is terrified of your question*',
    type: MediaTypes.Image,
    src: "https://media.giphy.com/media/rCQpekEiV3vyg/giphy.gif"
  },
  {
    title: '안녕하세요',
    type: MediaTypes.YouTube,
    videoId: 'k2rpW0EgTP0',
    start: 220,
    end: 221,
    muteAfterFirstLoop: false
  },
  {
    title: '',
    type: MediaTypes.YouTube,
    videoId: 'k2rpW0EgTP0',
    start: 246,
    end: 251,
    muteAfterFirstLoop: false
  },
  {
    title: '',
    type: MediaTypes.YouTube,
    videoId: 'k2rpW0EgTP0',
    start: 299,
    end: 302,
    muteAfterFirstLoop: false
  },
  {
    title: 'I love it. I love it',
    type: MediaTypes.YouTube,
    videoId: 'XTdGhnBjmK8',
    start: 210,
    end: 212,
    muteAfterFirstLoop: false
  },
  {
    title: '',
    type: MediaTypes.Video,
    src: 'https://media3.giphy.com/media/408udpVso65qgdPv2C/giphy.mp4'
  },
  {
    title: '',
    type: MediaTypes.Image,
    src: 'https://media1.tenor.com/images/e74218559046e95274190df06c29bf0a/tenor.gif?itemid=16671428'
  },
  {
    title: '',
    type: MediaTypes.YouTube,
    videoId: 'Dc1lchgI5WY',
    start: 112,
    end: 114,
    muteAfterFirstLoop: false
  },
  {
    title: '',
    type: MediaTypes.YouTube,
    videoId: '3l1o5sWj_8Y',
    start: 83,
    end: 88,
    muteAfterFirstLoop: false
  }
]

const MagicEightBallContainer = ({query}) => {
  if (query !== "") {
    let mediaComponent;
    var selectedEightBall = allResults[Math.floor(Math.random() * allResults.length)];
    switch(selectedEightBall.type) {
      case MediaTypes.YouTube:
        mediaComponent = <YouTubeClipRepeater
          videoId={selectedEightBall.videoId}
          start={selectedEightBall.start}
          end={selectedEightBall.end}
          muteAfterFirstLoop={selectedEightBall.muteAfterFirstLoop}
        />
        break;
      case MediaTypes.Iframe:
        mediaComponent = <iframe
          src={selectedEightBall.src}
          width={selectedEightBall.width}
          height={selectedEightBall.height}
          frameBorder="0"
          allowFullScreen
          title={selectedEightBall.title}
        />
        break;
      case MediaTypes.Video:
        mediaComponent = <video
          src={selectedEightBall.src}
          autoPlay
          loop
          muted
        />
        break;
      case MediaTypes.Image:
        mediaComponent = <img src={selectedEightBall.src} alt={selectedEightBall.title}/>
        break;
      default:
        mediaComponent = <div>????</div>
    }
    return <div><h3>{selectedEightBall.title}</h3>{mediaComponent}</div>
  } else {
    return <div />
  }
}

export default MagicEightBallContainer;
