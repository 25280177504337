import React, { Component } from "react";
import Header from "./components/Header";
import MagicEightBallContainer from "./components/MagicEightBallContainer"

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {query: ""};
  }

  // Prevent page reload, clear input, set URL and push history on submit
  handleSubmit = (e, searchInput) => {
    e.preventDefault();
    e.currentTarget.reset();
    this.setState({query: searchInput});
  };

  render() {
    return (
      <React.Fragment>
        <Header handleSubmit={this.handleSubmit} />
        <MagicEightBallContainer query={this.state.query} />
      </React.Fragment>
    )
  }
}

export default App;
