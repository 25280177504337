import React from "react";
import YouTube from "react-youtube"

const YouTubeClipRepeater = ({ videoId, start, end, muteAfterFirstLoop }) => {

  function onPlayerStateChange(event) {
    if (event.data === YouTube.PlayerState.ENDED) {
      event.target.seekTo(start);
      event.target.playVideo();
      if (muteAfterFirstLoop) {
        event.target.mute();
      }
    }
  }

  var opts = {
    playerVars: {
      autoplay: 1,
      controls: 0,
      fs: 0,
      iv_load_policy: 3,
      modestbranding: 1,
      rel: 0,
      start: start,
      end: end
    }
  }

  return (
    <YouTube videoId={videoId} onStateChange={onPlayerStateChange} opts={opts} />
  )
}

export default YouTubeClipRepeater
