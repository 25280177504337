import React from "react";
import Form from "./Form";

const Header = ({ history, handleSubmit }) => {
  return (
    <div>
      <img class='header-img' height='80' src='https://images.squarespace-cdn.com/content/v1/56c346b607eaa09d9189a870/1550542467535-E119QRYW5OMF26CZMH6B/ke17ZwdGBToddI8pDm48kJohzg_I3tEVBq3wBLk6e297gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z4YTzHvnKhyp6Da-NYroOW3ZGjoBKy3azqku80C789l0lCYWGxfdB_uf1_ERfebHZ6bGK5xZblWnuAN2Pluog9rF28oduXWd5O5W24FSsPjjg/FLAUNT-MAGAZINE-STEVEN-YEUN-3.jpg' alt=''/>
      <h1 class='header-text'>Ask Steven Yeun</h1>
      <img class='header-img' height='80' src='https://pbs.twimg.com/media/Een19IgWkAEVjPM.jpg' alt=''/>
      <Form history={history} handleSubmit={handleSubmit} />
    </div>
  );
};

export default Header;
